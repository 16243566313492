import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    solicitudId: '',
    departamentoId: 0,
    departamento: null,
    departamentoNombre: null,
    procesoId: 0,
    proceso: null,
    procesoNombre: null,
    solicitudEstadoId: 0,
    solicitudEstado: null,
    solicitudEstadoNombre: null,
    observaciones: '',
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, solicitudId) {
        state.solicitudId = solicitudId;
        state.departamentoId = 0;
        state.departamento = null;
        state.departamentoNombre = null;
        state.procesoId = 0;
        state.proceso = null;
        state.procesoNombre = null;
        state.solicitudEstadoId = 0;
        state.solicitudEstado = null;
        state.solicitudEstadoNombre = null;
        state.observaciones = '';
        state.rowVersion = null;
    },
    'SET_SOLICITUD'(state, solicitud) {
        state.departamentoId = solicitud.departamentoId;
        state.departamento = solicitud.departamento;
        state.departamentoNombre = solicitud.departamento? solicitud.departamento.nombre.trim() : null;
        state.procesoId = solicitud.procesoId;
        state.proceso = solicitud.proceso;
        state.procesoNombre = solicitud.proceso? solicitud.proceso.nombre.trim() : null;
        state.solicitudEstadoId = solicitud.solicitudEstadoId;
        state.solicitudEstado = solicitud.solicitudEstado;
        state.solicitudEstadoNombre = solicitud.solicitudEstado? solicitud.solicitudEstado.nombre.trim() : null;
        state.observaciones = '';
        state.rowVersion = solicitud.rowVersion;
    },
    'SET_DEPARTAMENTO'(state, departamento) {
        state.departamento = departamento;
        if (departamento) {
            state.departamentoId = departamento.id? departamento.id : 0;
            state.departamentoNombre = departamento.nombre? departamento.nombre.trim() : null;
        } else {
            state.departamentoId = 0;
            state.departamentoNombre = null;
        }

        state.procesoId = 0;
        state.proceso = null;
        state.procesoNombre = null;
    },
    'SET_PROCESO'(state, proceso) {
        state.proceso = proceso;
        if (proceso) {
            state.procesoId = proceso.id? proceso.id : 0;
            state.procesoNombre = proceso.nombre? proceso.nombre.trim() : null;
        } else {
            state.procesoId = 0;
            state.procesoNombre = null;
        }
    },
    'SET_SOLICITUD_ESTADO'(state, solicitudEstado) {
        state.solicitudEstado = solicitudEstado;
        if (solicitudEstado) {
            state.solicitudEstadoId = solicitudEstado.id? solicitudEstado.id : 0;
            state.solicitudEstadoNombre = solicitudEstado.nombre? solicitudEstado.nombre.trim() : null;
        } else {
            state.solicitudEstadoId = 0;
            state.solicitudEstadoNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', solicitudId);
            
            if (solicitudId != '') {
                apiAxios.get(`solicitudes/${solicitudId}/actualizacion`) 
                    .then(res => {
                        commit('SET_SOLICITUD', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    setDepartamento ( { commit }, departamento ) {
        return new Promise((resolve, reject) => {
            commit('SET_DEPARTAMENTO', departamento);
            resolve();
        })
    },
    setProceso ( { commit }, proceso ) {
        return new Promise((resolve, reject) => {
            commit('SET_PROCESO', proceso);
            resolve();
        })
    },
    setSolicitudEstado ( { commit }, solicitudEstado ) {
        return new Promise((resolve, reject) => {
            commit('SET_SOLICITUD_ESTADO', solicitudEstado);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let solicitudActualizacion= {
                    departamentoId: state.departamentoId,
                    procesoId: state.procesoId,
                    solicitudEstadoId: state.solicitudEstadoId,
                    observaciones: state.observaciones.trim(),
                    rowVersion: state.rowVersion
                };

                apiAxios.post(`solicitudes/${state.solicitudId}/actualizacion`, solicitudActualizacion)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}