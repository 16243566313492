import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    descripcion: '',
    habilitadoWeb: false,
    inactivo: false,
    rowVersion: null
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, programaId) {
        state.id = programaId;
        state.nombre = '';
        state.descripcion = '';
        state.habilitadoWeb = false;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_PROGRAMA'(state, programa) {
        state.nombre = programa.nombre.trim();
        state.descripcion = programa.descripcion.trim();
        state.habilitadoWeb = (/true/i).test(programa.habilitadoWeb);
        state.inactivo = (/true/i).test(programa.inactivo);
        state.rowVersion = programa.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, programaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', programaId);
            
            if (programaId != '') {
                apiAxios.get('programas/' + programaId) 
                    .then(res => {
                        commit('SET_PROGRAMA', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let programa= {
                    nombre: state.nombre.toUpperCase().trim(),
                    descripcion: state.descripcion.trim(),
                    habilitadoWeb: (/true/i).test(state.habilitadoWeb),
                    inactivo: (/true/i).test(state.inactivo)
                };

                let url = 'programas';
                if (state.id != '') {
                    url += '/' + state.id;
                    programa.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, programa)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    uploadReglamento ( {}, params ) {
        return new Promise((resolve, reject) => {
            /* Validamos los archivos a subir */
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'pdf') {
                error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten documentos en formato PDF.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten documentos de hasta 3MB.';
            }

            let formData = new FormData();
            formData.append('file', params.file);
            
            let options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }
            apiAxios.post(`programas/${params.programaId}/reglamento`, formData, options)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    deleteReglamento: ( {}, programaId ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `programas/${programaId}/reglamento/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}