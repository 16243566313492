import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    rankingColors: [ 
        '#E46651', 
        '#41B883', 
        '#FAFA05', 
        '#0000FF', 
        '#FFA500',
        '#17A204',
        '#04A29D',
        '#08CADD',
        '#DD08C6',
        '#AA1FC8',
        '#9B9B9B'
    ],
    
    programas: [],
    programasLabels: [],
    programasData: [],

    solicitudes: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_PROGRAMAS'(state) {
        state.programas = [];
        state.programasLabels = [];
        state.programasData = [];
    },
    'SET_PROGRAMAS'(state, programas) {
        if (programas) {
            state.programas = programas.map(programa => ({
                ...programa,
                pjeParticipacion: parseFloat(programa.pjeParticipacion) * parseFloat(100)
            }));
        }

        for (let index = 0; index < state.programas.length; index++) {
            if (index < state.programas.length) {
                state.programas[index].color = state.rankingColors[index];
            }
        }

        state.programasLabels = state.programas
            .map(programa => programa.programa.trim());
        
        state.programasData = state.programas
            .map(programa => helpers.methods.roundToTwo(parseFloat(programa.pjeParticipacion)));
    },
    'INICIALIZAR_SOLICITUDES'(state) {
        state.solicitudes = [];
    },
    'SET_SOLICITUDES'(state, solicitudes) {
        state.solicitudes = solicitudes;
    },
};

const actions = {
    getRankingProgramas: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_PROGRAMAS');

            apiAxios.get('programas/ranking')
                .then(res => {
                    commit('SET_PROGRAMAS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getStatusSolicitudesAbiertas: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_SOLICITUDES');

            apiAxios.get('solicitudes/status/abiertas')
                .then(res => {
                    commit('SET_SOLICITUDES', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    programas (state) {
        return state.programas;
    },
    programasLabels (state) {
        return state.programasLabels;
    },
    programasData (state) {
        return state.programasData;
    },
    solicitudes (state) {
        return state.solicitudes;
    },
    rankingColors (state) {
        return state.rankingColors;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}