import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';

const state = {
    formulario: [],
    campos: [],
    condiciones: []

};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.formulario = [];
        state.campos = [];
        state.condiciones = [];
    },
    'SET_DATOS'(state, params) {
        state.formulario = params.formulario;
        state.campos = params.campos;
        state.condiciones = params.condiciones.map(condiciones => ({
            ...condiciones,
            _showDetails: false
        }));
    },
};

const actions = {
    getDatos ( { commit }, formularioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (formularioId) {
                apiAxios.get('formularios/' + formularioId ) 
                    .then(res => {
                        let formulario = [
                            { 'nombre': 'programa', 'campo': 'Programa', 'valor': res.data.programa, 'formato': '' },
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'personaFisica', 'campo': 'Personas Físicas', 'valor': res.data.personaFisica? 'Si' : 'No', 'formato': '' },
                            { 'nombre': 'personaJuridica', 'campo': 'Personas Jurídicas', 'valor': res.data.personaJuridica? 'Si' : 'No', 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', 
                        {
                            formulario,
                            campos: res.data.campos,
                            condiciones: res.data.condiciones

                        });
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}