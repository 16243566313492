import { getField, updateField } from 'vuex-map-fields';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    formularioCampoTipoId: 0,
    formularioCampoTipo: null,
    formularioCampoTipoNombre: null,
    longitudMaxima: 0,
    orden: 0,

    /* Propiedades del tipo de campo */
    requiereLongitud: false,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.id = '';
        state.nombre = '';
        state.formularioCampoTipoId = 0;
        state.formularioCampoTipo = null;
        state.formularioCampoTipoNombre = null;
        state.longitudMaxima = 0;
        state.orden = 0;

        state.requiereLongitud = false;
    },
    'SET_CAMPO_GRUPO'(state, campo) {
        state.id = campo.id;
        state.nombre = campo.nombre;
        state.formularioCampoTipoId = campo.formularioCampoTipo? campo.formularioCampoTipo.id : 0;
        state.formularioCampoTipo = campo.formularioCampoTipo;
        state.formularioCampoTipoNombre = campo.formularioCampoTipo? campo.formularioCampoTipo.nombre.trim() : null;
        state.longitudMaxima = campo.longitudMaxima;
        state.orden = campo.orden;
        
        state.requiereLongitud = (/true/i).test(campo.formularioCampoTipo? campo.formularioCampoTipo.requiereLongitud : false);
    },
    'SET_FORMULARIO_CAMPO_TIPO'(state, formularioCampoTipo) {
        state.formularioCampoTipo = formularioCampoTipo;
        if (formularioCampoTipo) {
            state.formularioCampoTipoId = formularioCampoTipo.id? formularioCampoTipo.id : 0;
            state.formularioCampoTipoNombre = formularioCampoTipo.nombre? formularioCampoTipo.nombre.trim() : null;
            
            state.requiereLongitud = (/true/i).test(formularioCampoTipo.requiereLongitud);

            /* Reinicializamos los parametros de configuración */
            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }
        } else {
            state.formularioCampoTipoId = 0;
            state.formularioCampoTipoNombre = null;
            
            state.requiereLongitud = false;
            state.longitudMaxima = 0;
        }
    },
};

const getters = {
    getField,

    formularioCampoGrupo (state) {
        return {
            id: state.id,
            nombre: state.nombre.trim(),
            formularioCampoTipo: state.formularioCampoTipo,
            longitudMaxima: parseInt(state.longitudMaxima),
            orden: parseInt(state.orden),
        }
    }
};

const actions = {
    inicializar ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            if (campo) {
                commit('SET_CAMPO_GRUPO', campo);
                resolve();
            } else {
                resolve();
            }
        })
    },
    setFormularioCampoTipo ( { commit }, formularioCampoTipo ) {
        return new Promise((resolve, reject) => {
            commit('SET_FORMULARIO_CAMPO_TIPO', formularioCampoTipo);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}