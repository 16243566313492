import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    formularios: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    programa: null,
    nombre: '',
    incluirBorrados: false,
    sortByDescending: true,

    list: [],
    selectList: [],

    formularioDuplicadoId: ''
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.formularios = [];
    },
    'SET_FORMULARIOS'(state, formularios) {
        state.formularios = formularios;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, formularios) {
        state.list = formularios;
        state.selectList = formularios.map(formulario => formulario.nombre.trim());
    },

    'INICIALIZAR_FORMULARIO_DUPLICADO'(state) {
        state.formularioDuplicadoId = '';
    },
    'SET_FORMULARIO_DUPLICADO'(state, formularioDuplicadoId) {
        state.formularioDuplicadoId = formularioDuplicadoId;
    },
};

const actions = {
    getFormularios: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;

            if (state.programa) {
                if (state.programa.id) {
                    queryString += '&programaId=' + state.programa.id;
                }
            }
    
            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('formularios' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_FORMULARIOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    duplicarFormulario: ( { commit }, params ) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_FORMULARIO_DUPLICADO');

            apiAxios.post( `formularios/${params.formularioId}/duplicar`, params)
                .then(res => {
                    commit('SET_FORMULARIO_DUPLICADO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    delete: ( {}, formularioId ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `formularios/${formularioId}/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '';

            if (params) {
                if (params.programaId) {
                    if (params.programaId != 0) {
                        queryString = '?programaId=' + params.programaId;
                    }
                }
            }

            apiAxios.get('formularios/list' + queryString)
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getListByPrograma: ({ commit }, programaId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get(`formularios/programa/${programaId}/list`)
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}